import Navbar from '../components/Navbar';
import FarmShareBG from '../assets/FarmshareBG.jpg';
import Input from '../components/Input';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentUser } from '../redux/slices/myselfSlice';

//TODO: Make mandatory fields. 

export default function NewUserPage() {
  const user = useSelector((state) => state.auth.user);
  const [isMatch, setIsMatch] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    password: '',
    address: '',
    city: '',
    zip: '',
  });
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    dispatch(
      updateCurrentUser({
        firstName: formData.firstName,
        lastName: formData.lastName,
        password: confirmPassword,
        address: formData.address
      }),
    );
  };

  useEffect(() => {
    if (formData.password !== confirmPassword) {
      setIsMatch(false);
    } else {
      setIsMatch(true);
    }
  }, [confirmPassword, formData.password]);

  return (
    <div>
      <Navbar src={FarmShareBG} />
      <div className="flex flex-col items-center justify-center min-h-screen bg-sky-200 px-4">
        <div className="mb-8 text-center max-w-md">
          <p className="text-black text-lg">
            Welcome to the Farmshare program! We'd like to get to know you
            better before you start renting equipment. Please fill in the
            details below.
          </p>
        </div>

        <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-6 text-center">
            Enter Registration Details
          </h2>
          <form onSubmit={handleUpdate}>
            <Input
              name="firstName"
              type="text"
              label="First Name"
              value={formData.firstName}
              onChange={handleInputChange}
            />
            <Input
              name="lastName"
              type="text"
              label="Last Name"
              value={formData.lastName}
              onChange={handleInputChange}
            />
            <Input
              name="password"
              type="password"
              label="Password"
              value={formData.password}
              onChange={handleInputChange}
            />
            <Input
              name="confirmPassword"
              type="password"
              label="Confirm Password"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
            {!isMatch && (
              <p className="text-red-500 text-sm mt-2">
                {' '}
                Password did not match
              </p>
            )}
            <Input
              name="address"
              type="text"
              label="Address"
              value={formData.address}
              onChange={handleInputChange}
            />
            <Input
              name="city"
              type="text"
              label="City"
              value={formData.city}
              onChange={handleInputChange}
            />
            <Input
              name="zip"
              type="text"
              label="Zip Code"
              value={formData.zip}
              onChange={handleInputChange}
            />
            <button
              type="submit"
              className="w-full bg-[#2E7A32] text-white py-2 px-4 mt-4 rounded hover:bg-[#236326] transition duration-200"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
