export default function UserTable({
  sortedUsers,
  setDeleteModal,
  setUpdateModal,
  setChangeUser,
  loading,
  setAddUserModal,
}) {

  return (
    <div className="overflow-x-auto flex flex-col gap-5">
      <button
        className="bg-[#2E7A32] hover:bg-green-500 text-white font-bold py-1 px-3 rounded-full self-start mt-5 ml-5"
        onClick={() => setAddUserModal(true)}
      >
        Add User +
      </button>
      <table className="min-w-full table-auto border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-300 px-4 py-2 text-left text-[#2E7A32] font-bold">
              First Name
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left text-[#2E7A32] font-bold">
              Last Name
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left text-[#2E7A32] font-bold">
              Email
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left text-[#2E7A32] font-bold">
              Account Type
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left text-[#2E7A32] font-bold">
              Options
            </th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="4" className="text-center py-4">
                Loading...
              </td>
            </tr>
          ) : (
            sortedUsers.map((user) => (
              <tr key={user.email} className="bg-white hover:bg-gray-100">
                <td className="border border-gray-300 px-4 py-2">
                  {user.firstName ? user.firstName : "NOT REGISTERED"}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {user.lastName ? user.lastName : "NOT REGISTERED"}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {user.email}
                </td>
                <td className="border border-gray-300 px-4 py-2 capitalize">
                  {user.role}
                </td>
                <td className="border border-gray-300 px-4 py-2 capitalize">
                  <div className="flex justify-around">
                    <button
                      className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-3 rounded-md"
                      onClick={() => {
                        setUpdateModal(true);
                        setChangeUser(user._id);
                      }}
                    >
                      Update Role
                    </button>
                    <button
                      className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-3 rounded-md"
                      onClick={() => {
                        setDeleteModal(true);
                        setChangeUser(user._id);
                      }}
                    >
                      Delete User
                    </button>
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}
