import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectPrice({ setFilters }) {
  const [priceRange, setPriceRange] = React.useState('');

  const handleChange = (event) => {
    const selectedPriceRange = event.target.value;
    setPriceRange(selectedPriceRange);
    setFilters((prev) => ({ ...prev, price: selectedPriceRange }));
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 100, color: 'black'}} size="small">
      <InputLabel 
      sx = {{ fontWeight:700 , color: 'lightblack'}}
      id="select-price-label">
        Price
      </InputLabel>
      <Select
        labelId="select-price-label"
        id="select-price"
        value={priceRange}
        label="Price Range"
        onChange={handleChange}
        MenuProps={{
          PaperProps: {
            sx: {
              backgroundColor: 'black', // Set black background for the menu
              color: 'white', // Set text color to white for visibility
              '& .MuiMenuItem-root': { // Style individual menu items
                '&:hover': {
                  backgroundColor: '#333', // Optional: change background on hover
                },
              },
            },
          },
        }}
      >
        <MenuItem value="" >
          <em>None</em>
        </MenuItem>
        <MenuItem value="50">$0 - $50</MenuItem>
        <MenuItem value="100">$50 - $100</MenuItem>
        <MenuItem value="150">$100 - $150</MenuItem>
        <MenuItem value="200">$150 - $200</MenuItem>
        <MenuItem value="250">$200 - $250</MenuItem>
        <MenuItem value="300">$250 - $300</MenuItem>
        <MenuItem value="350">$300 - $350</MenuItem>
        <MenuItem value="400">$350 - $400</MenuItem>
        <MenuItem value="450">$400 - $450</MenuItem>
        <MenuItem value="500">$450 - $500</MenuItem>
      </Select>
    </FormControl>
  );
}
