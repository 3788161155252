import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api from '../../api';


export const getAllEquipment = createAsyncThunk(
  'equipment/getAllEquipment',
  async (_, { rejectWithValue }) => {
    try {
      const res = await api.get('/equipments');
      return res.data.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);


export const getSingleEquipment = createAsyncThunk(
  'equipment/getSingleEquipment',
  async (equipmentId, { rejectWithValue }) => {
    try {
      const res = await api.get(`/equipments/${equipmentId}`);
      return res.data.data.equipment;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

// New Async Thunk for Renting Equipment
export const rentEquipment = createAsyncThunk(
  'equipment/rentEquipment',
  async ({ id, quantity }, { rejectWithValue }) => {
    try {
      // Validate inputs
      if (!id) {
        throw new Error('Equipment ID is required');
      }
      if (!quantity || quantity <= 0) {
        throw new Error('Valid quantity is required');
      }

      console.log("Making rental request with:", { id, quantity });

      const res = await api.post(`/equipments/rent/${id}`, { 
        quantity: parseInt(quantity) 
      });
      
      if (res.data && res.data.success) {
        return { 
          id, 
          quantity: parseInt(quantity),
          equipment: res.data.equipment // If your API returns the updated equipment
        };
      } else {
        throw new Error(res.data?.message || 'Failed to rent equipment');
      }
    } catch (error) {
      console.error("Rental error:", error);
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

const initialState = {
  equipments: [],
  filteredEquipments: [],  // Add filteredEquipments to the state
  loading: false,
  totalPages: null,
  selectedEquipment: null,
  error: null,
  cart: [],
  rentLoading: false,     // New loading state for renting
};

const equipmentSlice = createSlice({
  name: 'equipment',
  initialState,

  reducers: {
    filterEquipment: (state, action) => {
      const { price, location, equipmentType, date } = action.payload;
      state.filteredEquipments = state.equipments.filter((item) => {
        const isPriceMatch = price ? item.rentalPrice <= price && item.rentalPrice > price - 50 : true;
        const isLocationMatch = location ? item.location === location : true;
        const isTypeMatch = equipmentType ? item.type === equipmentType : true;
        const isDateMatch = date ? new Date(item.availableDate) >= new Date(date) : true;
        return isPriceMatch && isLocationMatch && isTypeMatch && isDateMatch;
      });
    },

    removeFromCart: (state, action) => {
      const { id } = action.payload;
      state.cart = state.cart.filter((item) => item.id !== id);
    },

    updateCartQuantity: (state, action) => {
      const { id, quantity } = action.payload;
      const itemIndex = state.cart.findIndex((item) => item.id === id);
      if (itemIndex >= 0) {
        state.cart[itemIndex].quantity = quantity;
      }
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getAllEquipment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllEquipment.fulfilled, (state, action) => {
        state.loading = false;
        state.equipments = action.payload;
        state.filteredEquipments = action.payload; 

      })
      .addCase(getAllEquipment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getSingleEquipment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSingleEquipment.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedEquipment = action.payload;
      })
      .addCase(getSingleEquipment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Rent Equipment (Async Thunk)
      .addCase(rentEquipment.fulfilled, (state, action) => {
        state.rentLoading = false;
        const { id, quantity, equipment } = action.payload;
      
        // Update the cart
        const existingCartItem = state.cart.find(item => item.id === id);
        if (existingCartItem) {
          existingCartItem.quantity += quantity;
        } else {
          state.cart.push({ 
            id, 
            quantity,
            price: equipment?.rentalPrice || 0
          });
        }
      
        // Update equipment in the list
        const equipmentIndex = state.equipments.findIndex(item => item.id === id);
        if (equipmentIndex >= 0) {
          state.equipments[equipmentIndex].quantity -= quantity;
          // Update filtered equipments as well if they exist
          const filteredIndex = state.filteredEquipments.findIndex(item => item.id === id);
          if (filteredIndex >= 0) {
            state.filteredEquipments[filteredIndex].quantity -= quantity;
          }
        }

        toast.success(`Successfully rented ${quantity} items.`);
      })
  },
});


export const { filterEquipment } = equipmentSlice.actions;


export default equipmentSlice.reducer;
