import FarmShareBG from '../assets/FarmshareBG.jpg';
import Navbar from '../components/Navbar';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  getAllReservations,
  deleteReservation,
} from '../redux/slices/reservationSlice';
import { getSpecificUser } from '../redux/slices/userSlice';
import { getSingleEquipment } from '../redux/slices/equipmentsSlice';
import ReservationFilter from '../components/ReservationFilter';
import ReservationCard from '../components/ReservationCard';
import ReservationModal from '../components/ReservationModal';
import { toast } from 'react-toastify';

//TODO: edit details of a reservation (pickup, person, person's truck load cap, etc.). Display these details too. Need to update model schema.

export default function ReservationList() {
  const reservations = useSelector((state) => state.reservations.reservations);
  const loading = useSelector((state) => state.reservations.loading);
  const error = useSelector((state) => state.reservations.error);
  const [users, setUsers] = useState({});
  const [equipments, setEquipments] = useState({});
  const [filteredReservations, setFilteredReservations] = useState(null);
  const [approveModal, setApproveModal] = useState(false);
  const [currentFilter, setCurrentFilter] = useState('all');
  const [denyModal, setDenyModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [deleteReservationModal, setDeleteReservationModal] = useState(false);
  const [deleteRes, setDeleteRes] = useState(null);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllReservations());
  }, [dispatch]);

  useEffect(() => {
    const fetchUsers = async () => {
      const userIds = [
        ...new Set(reservations.map((reservation) => reservation.userId)),
      ];

      for (const userId of userIds) {
        if (!users[userId]) {
          try {
            const action = await dispatch(getSpecificUser(userId));

            if (getSpecificUser.fulfilled.match(action)) {
              setUsers((prevUsers) => ({
                ...prevUsers,
                [userId]: action.payload,
              }));
            } else {
              console.error(
                `Failed to fetch user with ID ${userId}:`,
                action.payload,
              );
            }
          } catch (err) {
            console.error(`Error fetching user with ID ${userId}:`, err);
          }
        }
      }
    };

    if (reservations.length > 0) {
      fetchUsers();
    }
  }, [dispatch, reservations]);

  useEffect(() => {
    const fetchEquipments = async () => {
      const equipIds = [
        ...new Set(reservations.map((reservation) => reservation.equipmentId)),
      ];

      for (const equipId of equipIds) {
        if (!equipments[equipId]) {
          try {
            const action = await dispatch(getSingleEquipment(equipId));

            if (getSingleEquipment.fulfilled.match(action)) {
              setEquipments((prevEquips) => ({
                ...prevEquips,
                [equipId]: action.payload,
              }));
            } else {
              console.error(
                `Failed to fetch user with ID ${equipId}:`,
                action.payload,
              );
            }
          } catch (err) {
            console.error(`Error fetching user with ID ${equipId}:`, err);
          }
        }
      }
    };

    fetchEquipments();
  }, [dispatch, reservations]);

  useEffect(() => {
    filterReservations(currentFilter);
  }, [reservations]);

  const filterReservations = (key) => {
    if (key === 'all') {
      setFilteredReservations(reservations);
    } else {
      let filtered = reservations.filter(
        (reservation) => reservation.reservationStatus === key,
      );
      setFilteredReservations(filtered);
    }
  };

  const handleDelete = async () => {
    if (deleteRes !== null) {
      try {
        await dispatch(deleteReservation(deleteRes));
      } catch (error) {
        toast.error('An unexpected error occurred. Please try again.');
      } finally {
        setDeleteReservationModal(false);
        setDeleteRes(null);
      }
    }
  };

  return (
    <div className="bg-sky-200 min-h-screen flex flex-col">
      <Navbar src={FarmShareBG} pageName="Reservation List" pageText="" />
      <ReservationFilter
        filterReservations={filterReservations}
        setCurrentFilter={setCurrentFilter}
        currentFilter={currentFilter}
      />
      {approveModal && (
        <ReservationModal
          setApproveModal={setApproveModal}
          setCancelModal={setCancelModal}
          setDenyModal={setDenyModal}
          selectedReservation={selectedReservation}
          mode={'Approve'}
          setSelectedReservation={setSelectedReservation}
        />
      )}

      {deleteReservationModal && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-md shadow-lg w-1/2 max-w-lg">
            <form>
              <label className="block mb-2 text-lg font-bold">
                Are You Sure You Want To{' '}
                <span className={`text-red-500`}> Delete </span>This Reservation
                From The Records?{' '}
              </label>
              <div className="flex justify-end">
                <button
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md"
                  type="button"
                  onClick={() => {
                    handleDelete();
                    setDeleteReservationModal(false);
                  }}
                >
                  Confirm
                </button>
                <button
                  className="ml-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-md"
                  onClick={() => setDeleteReservationModal(false)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {denyModal && (
        <ReservationModal
          setApproveModal={setApproveModal}
          setCancelModal={setCancelModal}
          setDenyModal={setDenyModal}
          mode={'Deny'}
          selectedReservation={selectedReservation}
          setSelectedReservation={setSelectedReservation}
        />
      )}

      {cancelModal && (
        <ReservationModal
          setApproveModal={setApproveModal}
          setCancelModal={setCancelModal}
          setDenyModal={setDenyModal}
          mode={'Cancel'}
          selectedReservation={selectedReservation}
          setSelectedReservation={setSelectedReservation}
        />
      )}

      <div className="flex-1 flex flex-col items-center">
        {filteredReservations && !loading && (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 w-full max-w-6xl mx-auto p-4">
            {filteredReservations.map((reservation) => {
              const user = users[reservation.userId];
              const name = user
                ? user.firstName + ' ' + user.lastName
                : 'Loading...';
              const equip = equipments[reservation.equipmentId];
              const equipName = equip ? equip.name : 'Loading...';
              return (
                <ReservationCard
                  key={reservation._id}
                  startDate={reservation.startDate}
                  endDate={reservation.endDate}
                  status={reservation.reservationStatus}
                  equipmentName={equipName}
                  renterName={name}
                  setApproveModal={setApproveModal}
                  setCancelModal={setCancelModal}
                  setDenyModal={setDenyModal}
                  setDeleteModal={setDeleteReservationModal}
                  setDeleteRes={setDeleteRes}
                  resId={reservation._id}
                  reservation={reservation}
                  setSelectedReservation={setSelectedReservation}
                />
              );
            })}
          </div>
        )}

        {loading && (
          <div className="flex justify-center items-center w-full h-full">
            <p className="text-gray-700">Loading reservations...</p>
          </div>
        )}

        {error && !reservations && (
          <div className="flex justify-center items-center w-full h-full">
            <p className="text-red-600 font-bold">{error} - Please try again</p>
          </div>
        )}
      </div>
    </div>
  );
}
