import React from 'react';
import EventCard from './EventCard';

const EventList = () => {
  return (
    <div className="container mx-auto mt-10 pb-10">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-y-10 justify-items-center">
        {/* <EventCard
          title="Vineyard Team"
          subheader="Vine-to-Wine Water Treatment Solutions"
          altImageText="Vine to Wine"
          image="https://www.vineyardteam.org/images/events/vine%20to%20wine%201.png"
          dateText="Apr 10, 2024"
          timeText="9:00am-11:00am"
          location="Lompoc, CA"
          mainText="Learn from a panel of experts about Reverse Osmosis as a water
              treatment option, their experience using it, the operational and
              technical processes to maintain RO systems, and cost analysis."
          signUpURL="https://vineyardteam.app.neoncrm.com/np/clients/vineyardteam/eventRegistration.jsp?event=197"
          learnMoreURL="https://vineyardteam.app.neoncrm.com/np/clients/vineyardteam/event.jsp?event=197&"
        />


        <EventCard
          title="Wild Farm Alliance"
          subheader="Tablas Creek Vineyard Field Day"
          altImageText="Vineyard Field Day"
          image="https://assets.nationbuilder.com/wildfarmalliance/pages/2010/features/original/Llamas-tablas-header.jpg?1710538713"
          dateText="Apr 25, 2024"
          timeText="8:00am-12:30pm"
          location="Paso Robles, CA"
          mainText="Join Tablas Creek Vineyard, Wild Farm Alliance (WFA), and The
          Vineyard Team out in the field! The event will feature a series of
          talks given by growers and agricultural professionals, a panel
          discussion, and a beneficial habitat walk."
          signUpURL="https://www.wildfarmalliance.org/tablas?utm_campaign=march_enews_2024&utm_medium=email&utm_source=wildfarmalliance"
          learnMoreURL="https://www.wildfarmalliance.org/tablas?utm_campaign=march_enews_2024&utm_medium=email&utm_source=wildfarmalliance"
        />

        <EventCard
          title="Elationscapes"
          subheader="Invitation to Permaculture Workshop"
          altImageText="Invitation to Permaculture Workshop"
          image="https://upload.wikimedia.org/wikipedia/commons/c/c8/Permaculture_garden.JPG"
          dateText="Apr 27, 2024"
          timeText="10:00am-1:00pm"
          location="San Miguel, CA"
          mainText="Join us for a tour of a non-profit grow-to-donate vegetable farm
          and fruit orchard in San Miguel. We will discuss the design of the
          farm, terracing, water system, crop layout and rotation, and
          Spring planting preparations."
          signUpURL="https://www.eventbrite.com/e/design-build-from-raw-land-to-small-farm-tickets-820397379647?aff=oddtdtcreator"
          learnMoreURL="https://slopermaculture.weebly.com/upcoming-events.html"
        /> */}

        <EventCard
          title="Los Angeles Farmer Listening Session"
          subheader="Community Alliance with Family Farmers"
          altImageText="Los Angeles Farmer Listening Session"
          image="https://i0.wp.com/caff.org/wp-content/uploads/2024/09/2.png?resize=1536%2C864&ssl=1"
          dateText="Oct 21, 2024"
          timeText="4:00pm-6:00pm"
          location="Los Angeles, CA"
          mainText="Join CAFF in conversation about challenges, concerns, and policy ideas to improve our food and agricultural system and to better support your farm."
          signUpURL="https://actionnetwork.org/events/los-angeles-farmer-listening-session-2024"
          learnMoreURL="https://actionnetwork.org/events/los-angeles-farmer-listening-session-2024"
        /> 

        <EventCard
          title="Climate-Smart Farming Demonstration"
          subheader="Field Meeting"
          altImageText="Climate-Smart Farming Demonstration Field Meeting"
          image="https://upload.wikimedia.org/wikipedia/commons/c/c8/Permaculture_garden.JPG"
          dateText="Nov 6, 2024"
          timeText="9:00am-11:45am"
          location="Camarillo, CA"
          mainText="Learn about: Cover crops, Compost tea, Soil health assessments."
          signUpURL="https://surveys.ucanr.edu/survey.cfm?surveynumber=43762"
          learnMoreURL="https://www.calfarmdemo.org/events/climate-smart-farming-demonstration-field-meeting/"
        /> 

        <EventCard
          title="Sustainable Ag Expo"
          subheader="& International Sustainable Winegrowing Summit"
          altImageText="Sustainable Ag Expo & International Sustainable Winegrowing Summit"
          image="https://www.vineyardteam.org/images/events/2024%20sae.png"
          dateText="Nov 12-13, 2024"
          timeText="8:00am-5:00pm"
          location="San Luis Obispo, CA"
          mainText="The Sustainable Ag Expo brings together researchers and growers so you can earn continuing education hours and improve your business all while connecting with peers and agriculture companies."
          signUpURL="https://vineyardteam.app.neoncrm.com/np/clients/vineyardteam/eventRegistration.jsp?event=192"
          learnMoreURL="https://vineyardteam.app.neoncrm.com/np/clients/vineyardteam/event.jsp?event=192&"
        /> 

        <EventCard
          title="Sustainable Winegrowing On-Demand"
          subheader="Virtual Roundtable 2 | Western SARE"
          altImageText="Sustainable Winegrowing On-Demand"
          image="https://www.vineyardteam.org/images/events/Virtual%20round%20table%202_western%20SARE_800x500.jpg"
          dateText="Dec 10, 2024"
          timeText="9:00am-10:00am"
          location="Online"
          mainText="Join the final virtual grower roundtable included in Sustainable Winegrowing On-Demand. 3 presenters from the top most watched courses will be online to answer your questions.."
          signUpURL="https://vineyardteam.app.neoncrm.com/np/clients/vineyardteam/eventRegistration.jsp?event=253"
          learnMoreURL="https://vineyardteam.app.neoncrm.com/np/clients/vineyardteam/event.jsp?event=253&"
        /> 

        <EventCard
          title="EcoFarm"
          subheader="45th Annual Conference"
          altImageText="EcoFarm 45th Annual Conference"
          image="https://images.squarespace-cdn.com/content/v1/64c2ee1fc10f2205e1d2508f/1708115281326-QZNMXTP73HNTUHF890VW/image-asset.jpeg"
          dateText="Jan 22-25, 2025"
          timeText="all day"
          location="Location TBA"
          mainText="EcoFarm advances ecological and just farming and food systems
          through learning, convening, celebrating, and advocating."
          signUpURL="https://www.eco-farm.org/"
          learnMoreURL="https://www.eco-farm.org/"
        />



      </div>
    </div>
  );
};

export default EventList;
