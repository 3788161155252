import React from 'react';
import { FaFacebookSquare, FaInstagram, FaTwitterSquare } from 'react-icons/fa';
import Logo from '../assets/Logo.svg';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <div className="h-[2rem] w-auto bg-[#2E7A32]"></div>
      <div className=" mx-auto py-16 px-2 grid lg:grid-cols-3 gap-8 bg-[#A8C356] text-[white]">
        <div>
          <img className="w-[100px] mx-auto my-4" src={Logo} alt="/" />
          <div className="flex justify-center md:w-[55%] my-6 mx-auto">
            <FaFacebookSquare size={30} className="mx-2" />
            <FaInstagram size={30} className="mx-2" />
            <FaTwitterSquare size={30} className="mx-2" />
          </div>
        </div>
        <div className="lg:col-span-2 flex justify-evenly mt-6 md:text-end">
          <div>
          <ul>
            <Link to="/">
              <li className="font-medium text-bg-[#2E7A32]">Home</li>
            </Link>
            <Link to="/about-us">
              <li className="font-medium text-bg-[#2E7A32]">About SLI</li>
            </Link>
            <Link to="/farmshare">
              <li className="font-medium text-bg-[#2E7A32]">Farmshare</li>
            </Link>
            <Link to="/events">
              <li className="font-medium text-bg-[#2E7A32]">Events</li>
            </Link>
            <Link to="/Resources">
              <li className="font-medium text-bg-[#2E7A32]">Resources</li>
            </Link>
            <Link to="/privacy">
              <li className="font-medium text-bg-[#2E7A32]">Privacy Notice</li>
            </Link>
            </ul>
          </div>
          <div>
            <ul>
            <Link to="https://www.us-ltrcd.org/contact-us" target='_blank'>
              <li className="font-medium text-bg-[#2E7A32]">Contact Us</li>
            </Link>
            {/*
              <li className="py-2">Login</li>

              <li className="py-2">Shop</li>
              <li className="py-2">FAQs</li>
            */}
            </ul>
          </div>
          {/*<div>
            <Link to="/events">
              <h6 className="font-medium text-bg-[#2E7A32]">Events</h6>
            </Link>
            <ul>
              <li className="py-2">Workshops</li>
              <li className="py-2">Blog</li>
              <li className="py-2">Jobs</li>
            </ul>
          </div>
          <div>
            <Link to="/resources">
              <h6 className="font-medium text-bg-[#2E7A32]">Resources</h6>
            </Link>
            <ul>
              <li className="py-2">Donations</li>
              <li className="py-2">Policy</li>
              <li className="py-2">Terms</li>
            </ul>
          </div>
          */}
        </div>
      </div>
    </>
  );
};

export default Footer;
