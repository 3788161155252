import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { toast } from 'react-toastify';

export const getAllUsers = createAsyncThunk(
  'users/getAllUsers',
  async (_, { rejectWithValue }) => {
    try {
      const res = await api.get(`/users`);

      return res.data.data;
    } catch (error) {
      toast.warn(error.errors.message);
      const errorMessage =
        error.response?.data?.message || error.message || error.errors.message;
      return rejectWithValue(errorMessage);
    }
  },
);

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (userID, { rejectWithValue }) => {
    try {
      await api.delete(`/users/${userID}`);
      return userID;
    } catch (error) {
      toast.warn(error.errors.message);
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  },
);

export const getSpecificUser = createAsyncThunk(
  'users/getSpecificUser',
  async (userID, { rejectWithValue }) => {
    try {
      const res = await api.get(`/users/${userID}`);

      return res.data.data.user;
    } catch (error) {
      toast.warn(error.errors.message);
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  },
);

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async (userInfo, { rejectWithValue }) => {
    const {
      userId,
      firstName,
      lastName,
      password,
      practices,
      farmLocations,
      role,
    } = userInfo;
    try {
      await api.put(`/users/${userId}`, {
        firstName,
        lastName,
        password,
        practices,
        farmLocations,
        role,
      });
      return { userId: userId, role: role };
    } catch (error) {
      // toast.warn(error.errors.message);
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  },
);

/**Register new account*/
export const register = createAsyncThunk('auth/register', async (formData) => {
  try {
    const res = await api.post('/users', formData);
    toast.success(`Successfully Registered!, ${formData.email}!`);
    return res.data.data.user;
  } catch (error) {
    toast.warn(error.errors.message);
    throw error;
  }
});

const initialState = {
  user: null,
  users: [],
  loading: false,
  error: null,
  signUpSuccess: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.users = action.payload.users;
        state.loading = false;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = state.users.filter((user) => user._id !== action.payload);
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteUser.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        const userId = action.payload.userId;
        const role = action.payload.role;
        state.loading = false;
        for (let user of state.users) {
          if (user._id === userId) {
            user.role = role;
          }
        }
      })
      .addCase(updateUser.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(register.pending, (state) => {
        state.loading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.loading = false;
        state.signUpSuccess = true;
        state.users = [...state.users, action.payload];
      })
      .addCase(register.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getSpecificUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSpecificUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(getSpecificUser.pending, (state) => {
        state.loading = true;
      });
  },
});

export default userSlice.reducer;
