import React, { Fragment, useEffect } from 'react';
import Navbar from '../components/Navbar';
import FarmshareBG from '../assets/FarmshareBG.jpg';
import DetailEquipment from '../components/DetailEquipment';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getSingleEquipment } from '../redux/slices/equipmentsSlice';
import { rentEquipment } from '../redux/slices/equipmentsSlice'

const DetailEquipmentPage = () => {
  const { equipmentId } = useParams();
  const dispatch = useDispatch();
  const equipment = useSelector((state) => state.equipments.selectedEquipment);
  const loading = useSelector((state) => state.equipments.loading);
  const error = useSelector((state) => state.equipments.error);
  useEffect(() => {
    dispatch(getSingleEquipment(equipmentId));
  }, [equipmentId, dispatch]);

  useEffect(() => {
    console.log('EQUIPMENT:', equipment);
  }, [equipment]);

  const handleRentClick = (quantity) => {
    dispatch(rentEquipment({ id: equipmentId, quantity }));
  };
  return (
    <Fragment>
      {!equipment ? (
        <div>hehe</div>
      ) : (
        <div className="bg-amber-100">
          <Navbar src={FarmshareBG} pageName={`Farmshare`} />
          <DetailEquipment equip={equipment} onRentClick={handleRentClick} />
        </div>
      )}
    </Fragment>
  );
};

export default DetailEquipmentPage;
