import React from 'react';

const Input = ({ name, type, label, value, onChange }) => {
  return (
    <div className="flex flex-col py-2">
      <label>{label}</label>
      <input
        className="border p-2"
        type={type}
        value={value}
        onChange={onChange}
        id={name}
        name={name}
      />
    </div>
  );
};

export default Input;
