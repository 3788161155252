import React from 'react';
import EquipmentList from '../components/EquipmentList';
import Navbar from '../components/Navbar';
import EquipmentFilter from '../components/EquipmentFilter';
import FarmShareBG from '../assets/FarmshareBG.jpg';

const FarmsharePage = () => {
  return (
    <div className="bg-[#b3deae]">
      <Navbar 
        src={FarmShareBG} 
        pageName={`Farmshare`} 
        pageText={"USLTRCD has partnered with Cal Poly BioResource and Agricultural Engineering (BRAE) faculty and students to" 
        + " design and construct biochar kilns, compost spreaders, ploughs, no-till drills, wool pellitizers, and other equipment." 
        + "  See our current stock of equipment below."}/>
      <EquipmentFilter />
      <EquipmentList />
    </div>
  );
};

export default FarmsharePage;
