import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectLocation({ setFilters }) {
  const [location, setLocation] = React.useState('');

  const handleChange = (event) => {
    const selectedLocation = event.target.value;
    setLocation(selectedLocation);
    setFilters((prev) => ({ ...prev, location: selectedLocation })); 
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 170, color: 'black' }} size="small">
      <InputLabel sx = {{ fontWeight:700 , color: 'lightblack'}} id="demo-select-small-label">Location</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={location}
        label="Location"
        onChange={handleChange}
        MenuProps={{
          PaperProps: {
            sx: {
              backgroundColor: 'black', // Set black background for the menu
              color: 'white', // Set text color to white for visibility
              '& .MuiMenuItem-root': { // Style individual menu items
                '&:hover': {
                  backgroundColor: '#333', // Optional: change background on hover
                },
              },
            },
          },
        }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value="San Luis Obispo">San Luis Obispo</MenuItem>
        <MenuItem value="Los Angeles">Los Angeles</MenuItem>
        <MenuItem value="Santa Barbara">Santa Barbara</MenuItem> 
      </Select>
    </FormControl>
  );
}
