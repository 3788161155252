import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { toast } from 'react-toastify';

/**Login with email password*/
export const loginRequest = createAsyncThunk(
  'auth/loginRequest',
  async ({ email, password }) => {
    try {
      const res = await api.post('auth/login', { email, password });
      toast.success(`Welcome back!`);
      return res.data.data;
    } catch (error) {
      toast.error(error.errors.message);
      throw error;
    }
  },
);

const initialState = {
  user: {},
  isAuthenticated: null,
  loading: false,
  messages: [],
  acessToken: '',
  error: null,
  newUser: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logoutRequested: (state) => {
      localStorage.removeItem('token');
      delete api.defaults.headers.common['Authorization'];
      return { ...state, isAuthenticated: false };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginRequest.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(loginRequest.fulfilled, (state, action) => {
        return {
          ...state,
          user: action.payload.user,
          accessToken: action.payload.accessToken,
          loading: false,
          isAuthenticated: true,
        };
      })
      .addCase(loginRequest.rejected, (state) => {
        return { ...state, loading: false, isAuthenticated: false };
      });
  },
});

export const { logoutRequested } = authSlice.actions;

export default authSlice.reducer;