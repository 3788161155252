import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SelectPrice from './SelectPrice';
import SelectLocation from './SelectLocation';
import SelectEquipment from './SelectEquipment';
import { useDispatch } from 'react-redux';
import { filterEquipment } from '../redux/slices/equipmentsSlice'

const EquipmentFilter = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    price: '',
    location: '',
    equipmentType: '',
    date: '',
  });

  const handleFindEquipment = () => {
    // Dispatch the filter action with the current filters
    dispatch(filterEquipment(filters));
  };

  return (
    <div className="hidden mx-auto w-[750px] md:flex justify-between items-center rounded-full bg-[#A8C356] mt-10 flex-1 pl-4 pr-4">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <SelectPrice setFilters={setFilters} />
        {/* <DatePicker
          label="Date Available"
          slotProps={{ textField: { size: 'small' } }}
          onChange={(newValue) => setFilters((prev) => ({ ...prev, date: newValue }))} // Update date in filters
        /> */}
        <SelectLocation setFilters={setFilters} />
        <SelectEquipment setFilters={setFilters} />
        <button 
          className="bg-[#156326] hover:bg-[#1563269c] text-white font-bold py-2 px-2 rounded-full justify-end"
          onClick={handleFindEquipment}
        >
          Find equipment
        </button>
      </LocalizationProvider>
    </div>
  );
};

export default EquipmentFilter;
