export default function UpdateUserModal({
  setSelectRole,
  selectRole,
  setUpdateModal,
  setChangeUser,
  handleUpdate,
}) {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-md shadow-lg w-1/2 max-w-lg">
        <form onSubmit={handleUpdate}>
          <label className="block mb-2 text-lg font-bold">
            Set Role for User
          </label>
          <select
            className="block w-full mb-4 p-2 border border-gray-300 rounded-md"
            value={selectRole}
            onChange={(e) => setSelectRole(e.target.value)}
          >
            <option value="user">User</option>
            <option value="admin">Admin</option>
          </select>
          <div className="flex justify-end">
            <button
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md"
              type="submit"
            >
              Save
            </button>
            <button
              className="ml-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-md"
              onClick={() => {
                setUpdateModal(false);
                setChangeUser(null);
              }}
              type="button"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
