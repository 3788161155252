import Input from './Input';
import { useState } from 'react';
import { register } from '../redux/slices/userSlice';
import { useDispatch } from 'react-redux';

export default function AddUserModal({ setAddUserModal }) {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: '',
  });

  const handleRegister = (e) => {
    e.preventDefault();
    dispatch(register(formData));
    setAddUserModal(false);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-md shadow-lg w-1/2 max-w-lg">
        <form onSubmit={handleRegister}>
          <label className="block mb-2 text-lg font-bold">
            Please Enter New User Info:
          </label>
          <Input
            name="email"
            type="text"
            label="Email:"
            value={formData.email}
            onChange={handleInputChange}
          />
          <div className="flex justify-end">
            <button
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md"
              type="submit"
            >
              Confirm
            </button>
            <button
              className="ml-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-md"
              onClick={() => {
                setAddUserModal(false);
              }}
              type="button"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
