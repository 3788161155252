import { updateReservation } from '../redux/slices/reservationSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

export default function ReservationModal({
  setApproveModal,
  setDenyModal,
  setCancelModal,
  mode,
  selectedReservation,
  setSelectedReservation,
}) {
  const dispatch = useDispatch();

  const colors = {
    Approve: 'green-500',
    Deny: 'red-500',
    Cancel: 'yellow-500',
  };

  const hov = {
    Approve: 'green-700',
    Deny: 'red-700',
    Cancel: 'yellow-700',
  };

  const handleUpdateStatus = async (status) => {
    if (selectedReservation) {
      try {
        await dispatch(
          updateReservation({
            ...selectedReservation,
            reservationStatus: status,
          }),
        );
        setSelectedReservation(null);
        if (status === 'approved') setApproveModal(false);
        if (status === 'canceled') {
          setCancelModal(false);
          setDenyModal(false);
        }
      } catch (error) {
        toast.error('Could not perform the action. Please try again.');
      }
    } else {
      toast.error('Could not perform the action. Please try again.');
    }
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-md shadow-lg w-1/2 max-w-lg">
        <form>
          <label className="block mb-2 text-lg font-bold">
            Are You Sure You Want To{' '}
            <span className={`text-${colors[mode]}`}> {mode} </span>This User's
            Reservation?{' '}
          </label>
          <div className="flex justify-end">
            <button
              className={`bg-${colors[mode]} hover:bg-${hov[mode]} text-white font-bold py-2 px-4 rounded-md`}
              type="button"
              onClick={() => {
                if (mode === 'Deny' || mode === 'Cancel') {
                  handleUpdateStatus('canceled');
                } else if (mode === 'Approve') {
                  handleUpdateStatus('approved');
                }
              }}
            >
              Confirm
            </button>
            <button
              className="ml-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-md"
              onClick={() => {
                if (mode === 'Approve') {
                  setApproveModal(false);
                } else if (mode === 'Cancel') {
                  setCancelModal(false);
                } else if (mode === 'Deny') {
                  setDenyModal(false);
                }
              }}
              type="button"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
