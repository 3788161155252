import React, { Fragment, useEffect, useState } from 'react';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { getCurrentUser } from './redux/slices/myselfSlice';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from './components/Navbar';
import Login from './pages/Login';
import Signup from './pages/Signup';
import ErrorPage from './pages/ErrorPage';
import HomePage from './pages/HomePage';
import Footer from './components/Footer';
import FarmsharePage from './pages/FarmsharePage';
import FarmsharePageInProgress from './pages/FarmsharePageInProgress';
import AchievementsPage from './pages/AchievementsPage';
import AboutUsPage from './pages/AboutUsPage';
import RCDPage from './pages/RCDPage';
import ContactUsPage from './pages/ContactUsPage';
import EventPage from './pages/EventPage';
import ResourcesPage from './pages/ResourcesPage';
import PrivacyPage from './pages/PrivacyPage';
import DetailEquipmentPage from './pages/DetailEquipmentPage';
import HomePageBG from './assets/HomepageBG.jpg';
import UserList from './pages/UserList';
import ReservationList from './pages/ReservationList';
import NewUserPage from './pages/NewUserPage';
import UserProfilePage from './pages/UserProfilePage';

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  const user = useSelector((state) => state.myself.user);
  const isAuth = useSelector((state) => state.myself.isAuthenticated);
  const newUser = useSelector((state) => state.myself.newUser);

  const isAdmin = user?.role === 'admin';

  // const isAdmin = true;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        {/* <Route path="/signup" element={<Signup />} /> */}
        <Route path="/events" element={<EventPage />} />
        <Route
          path="/farmshare"
          element={newUser ? <NewUserPage /> : <FarmsharePage />}
        />
        <Route
          path="/farmshare:equipmentId"
          element={<DetailEquipmentPage />}
        />
        <Route
          path="/farmshare/profile"
          element={isAuth ? <UserProfilePage /> : <HomePage />}
        />
        <Route path="/farmshareIP" element={<FarmsharePageInProgress />} />
        <Route path="/achievements" element={<AchievementsPage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/rcd" element={<RCDPage />} />
        <Route path="/resources" element={<ResourcesPage />} />
        <Route
          path="/userlist"
          element={isAdmin ? <UserList /> : <HomePage />}
        />
        <Route
          path="/reservationlist"
          element={isAdmin ? <ReservationList /> : <HomePage />}
        />
        <Route
          path="*"
          element={
            <Fragment>
              <Navbar src={HomePageBG} pageName={``} />
              <ErrorPage />
            </Fragment>
          }
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
