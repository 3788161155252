import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api from '../../api';

//TODO: thunk for updating reservation. need to upate schema
//TODO: isAuthenticated in authSlice as well
//TODO: figure out how to handle newUser. In slice or client?

/**Get current user infomation*/
export const getCurrentUser = createAsyncThunk(
  'myself/getCurrentUser',
  async (_, { rejectWithValue }) => {
    try {
      const res = await api.get(`/myself`);
      return res.data.data;
    } catch (error) {
      // toast.warn(error.errors.message);
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  },
);

/**Update the Current User's Info */
/**TODO: incomplete data inputs (first, last, pwd, adddr) need more */
export const updateCurrentUser = createAsyncThunk(
  'myself/updateCurrentUser',
  async (userInfo, { rejectWithValue }) => {
    const { firstName, lastName, password, oldPassword, address } = userInfo;
    try {
      const res = await api.put(`/myself/`, {
        firstName,
        lastName,
        oldPassword,
        password,
        address,
      });
      toast.success('Success!');
      return res.data.data.user;
    } catch (error) {
      toast.warn(error.errors.message);
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  },
);

/**Get the current user's reservations */
export const getMyReservations = createAsyncThunk(
  'myself/getMyReservations',
  async (_, { rejectWithValue }) => {
    try {
      const res = await api.get(`/myself/reservations`);
      toast.success('Success!');
      return res.data.data;
    } catch (error) {
      toast.warn(error.errors.message);
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  },
);

/**Make a reservation for the current user */
export const makeReservation = createAsyncThunk(
  'myself/makeReservation',
  async (_, { rejectWithValue }) => {
    try {
      const res = await api.post(`/myself/reservations`);
      toast.success('Success!');
      return res.data.data;
    } catch (error) {
      toast.warn(error.errors.message);
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  },
);

/**Update a reservation for the current user */
export const updateMyReservation = createAsyncThunk(
  'myself/updateMyReservation',
  async (reservation, { rejectWithValue }) => {
    try {
      const resId = reservation._id;
      const res = await api.put(`/myself/reservation/${resId}`, reservation);
      toast.success('Success!');
      return res.data.data;
    } catch (error) {
      toast.warn(error.errors.message);
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  },
);

/**Delete a reservation for the current user */
export const deleteMyReservation = createAsyncThunk(
  'myself/deleteMyReservation',
  async (reservationId, { rejectWithValue }) => {
    try {
      await api.delete(`/myself/reservations/${reservationId}`);
      toast.success('Success!');
      return reservationId;
    } catch (error) {
      toast.warn(error.errors.message);
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  },
);

const initialState = {
  user: {},
  reservations: [],
  isAuthenticated: null,
  loading: false,
  error: null,
  newUser: false,
};

const myselfSlice = createSlice({
  name: 'myself',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentUser.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.loading = false;
        state.isAuthenticated = true;
        state.newUser = action.payload.user?.firstName === undefined;
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.isAuthenticated = false;
      })
      .addCase(updateCurrentUser.pending, (state) => {
        state.loading = false;
      })
      .addCase(updateCurrentUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateCurrentUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.newUser = action.payload?.firstName === undefined;
      })
      .addCase(getMyReservations.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMyReservations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getMyReservations.fulfilled, (state, action) => {
        state.loading = false;
        state.reservations = action.payload;
      })
      .addCase(makeReservation.pending, (state) => {
        state.loading = true;
      })
      .addCase(makeReservation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(makeReservation.fulfilled, (state, action) => {
        state.loading = false;
        state.reservations = [...state.reservations, action.payload];
      })
      .addCase(deleteMyReservation.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteMyReservation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteMyReservation.fulfilled, (state, action) => {
        state.loading = false;
        state.reservations = state.reservations.filter(
          (reservation) => reservation._id !== action.payload,
        );
      })
      .addCase(updateMyReservation.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateMyReservation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateMyReservation.fulfilled, (state, action) => {
        state.loading = false;
        state.reservations = state.reservations.filter(
          (reservation) => reservation._id !== action.payload,
        );
      });
  },
});

export default myselfSlice.reducer;
