import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import Logo from '../assets/Logo.svg';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ExternalLinkButton from './ExternalLinkButton';
import { logoutRequested } from '../redux/slices/authSlice';

const Navbar = ({ src, pageName, pageText }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.myself.isAuthenticated);
  const isLoading = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  useEffect(() => {}, [isAuthenticated]);

  return (
    <>
      <ToastContainer />
      <div className="h-[2rem] w-auto bg-[#2E7A32] relative">
        <div className="p-1 text-white "></div>
      </div>
      <div
        style={{ color: '#FFEB83' }}
        className="font-Merriweather-Sans font-extrabold text-shadow-lg text-4xl max-w-[70vw] md:max-w-[80vw] md:text-5xl left-[8vw] md:left-16 lg:left-[10vw] top-[80px] md:top-[180px] lg:top-[250px] lg:text-6xl absolute z-20"
      >
        {pageName}
        <div className="font-Merriweather-Sans text-lg md:flex w-auto h-auto mt-3  md:max-w-[70vw] md:text-2xl font-bold">
          {pageText}
        </div>
      </div>
      <div
        style={{ backgroundImage: `url(${src})` }}
        className={`flex items-start min-h-[350px] md:min-h-[400px] lg:min-h-[500px] text-[#2E7A32] text-xs sm:text-md md:text-lg lg:text-lg xl:text-xl 3xl:text-2xl bg-cover bg-no-repeat bg-center relative pl-31 pt-14`}
      >
        <Link to="/">
          <div className="pb-80 mr-8 hidden xl:block">
            <img
              className="hidden md:flex w-[100px] h-auto ml-[10vw]"
              src={Logo}
              alt="/"
            />
          </div>
        </Link>
        <div className="">
          <ul className="hidden mt-5 md:flex font-Merriweather-Sans text-center ml-[8vw] md:ml-16 lg:ml-[10vw] xl:ml-0">
            <Link to="/">
              <li className="mx-2 px-6 py-2 bg-[#A8C356] min-w-[9vw] rounded-full flex justify-center shadow">
                Home
              </li>
            </Link>
            <Link to="/about-us">
              <li className="mx-2 px-6 py-2 bg-[#A8C356] min-w-[9vw] rounded-full flex justify-center shadow">
                About&nbsp;SLI
              </li>
            </Link>
            <Link to="/farmshare">
              <li className="mx-2 px-6 py-2 bg-[#A8C356] min-w-[9vw] rounded-full flex justify-center shadow">
                Farmshare
              </li>
            </Link>
            <Link to="/events">
              <li className="mx-2 px-6 py-2 bg-[#A8C356] min-w-[9vw] rounded-full flex justify-center shadow">
                Events
              </li>
            </Link>
            <Link to="/resources">
              <li className="mx-2 px-6 py-2 bg-[#A8C356] min-w-[9w-[8vw]vw] rounded-full flex justify-center shadow">
                Resources
              </li>
            </Link>
          </ul>
        </div>
        {/* {isAuthenticated && (
          <div
            onClick={(e) => {
              e.preventDefault();
              dispatch(logoutRequested());
              navigate('/');
            }}
          >
            <ul className="hidden my-5 mx-2 md:flex font-Merriweather-Sans">
              <li className="bg-white cursor-pointer p-2 text-black rounded-full md:w-20 lg:w-24 flex items-center justify-center">
                Logout
              </li>
            </ul>
          </div>
        )} */}
        {!isAuthenticated && (
          <div className="">
            <a
              href="https://usltrcd-prod-mobile.f2-cloud.com/SelfService/submission/submit/Intake_form"
              target="_blank"
            >
              <ul className="hidden m-5 lg:flex font-Merriweather-Sans">
                <li className="block mx-2 px-3 py-2 absolute right-[10vw] bg-lime-800 text-white font-bold rounded-full w-auto flex items-center justify-center shadow">
                  <span>Enroll&nbsp;Now!</span>
                </li>
              </ul>
            </a>
          </div>
        )}
        {isAuthenticated && (
          <div className="">
            <Link
              className="hidden m-5 lg:flex font-Merriweather-Sans"
              to="/farmshare"
            >
              <li className="block mx-2 px-3 py-2 absolute right-[10vw] bg-lime-800 text-white font-bold rounded-full w-auto flex items-center justify-center shadow">
                My Reservations
              </li>
            </Link>
          </div>
        )}
        <div
          onClick={handleNav}
          className="block md:hidden rounded text-[#FFEB83] font-Merriweather-Sans absolute bg-[#2E7A32] right-7 md:right-14 top-12 cursor-pointer"
        >
          {nav ? <div /> : <AiOutlineMenu className="p-1" size={30} />}
        </div>
        {/* <AiOutlineClose size={20} /> */}
        <ul
          className={
            nav
              ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-[#A8C356] bg-[#A8C356] ease-in-out duration-500 z-20'
              : 'ease-in-out duration-500 fixed left-[-100%]'
          }
        >
          <div
            onClick={handleNav}
            className="cursor-pointer absolute right-5 top-5"
          >
            <AiOutlineClose size={20} />
          </div>

          <Link to="/">
            <h1 className="w-auto text-3xl font-bold text-[#2E7A32] m-4">
              SLI
            </h1>
          </Link>
          <Link to="/">
            <li className="p-4 border-b border-[#2E7A32]">Home</li>
          </Link>
          <Link to="/about-us">
            <li className="p-4 border-b border-[#2E7A32]">About SLI</li>
          </Link>
          <Link to="/farmshare">
            <li className="p-4 border-b border-[#2E7A32]">Farmshare</li>
          </Link>
          <Link to="/events">
            <li className="p-4 border-b border-[#2E7A32]">Events</li>
          </Link>
          <Link to="/resources">
            <li className="p-4 border-b border-[#2E7A32]">Resources</li>
          </Link>
          {/*<Link to="/signup">
            <li className="p-4">Sign Up</li>
        </Link>*/}
          <div>
            <li className="p-4">
              <a
                href="https://usltrcd-prod-mobile.f2-cloud.com/SelfService/submission/submit/Intake_form"
                target="_blank"
              >
                Enroll
              </a>
            </li>
          </div>
          {/*<Link to="/signup">
            <li className="p-4">Sign Up</li>
        </Link>*/}
        </ul>
      </div>
    </>
  );
};

export default Navbar;
