import Navbar from '../components/Navbar';
import FarmShareBG from '../assets/FarmshareBG.jpg';
import Input from '../components/Input';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentUser } from '../redux/slices/myselfSlice';
import { useNavigate } from 'react-router-dom';

export default function UserProfilePage() {
  const [currentSection, setCurrentSection] = useState('info');
  const user = useSelector((state) => state.myself.user);
  const loading = useSelector((state) => state.myself.loading);
  const error = useSelector((state) => state.myself.error);
  const navigate = useNavigate();

  console.log(user);
  if (!user) navigate('/login');

  return (
    <div>
      <Navbar src={FarmShareBG} pageName={`User Profile`} pageText={''} />
      <div className="flex justify-around items-center p-4 bg-white shadow-md rounded-lg border-b-2">
        <button
          onClick={() => {
            setCurrentSection('info');
          }}
          className="px-4 py-2 text-sm font-semibold rounded-full transition-all duration-300 bg-[#2E7A32] text-white shadow-md"
        >
          User Information
        </button>
        <button
          onClick={() => {
            setCurrentSection('creds');
          }}
          className="px-4 py-2 text-sm font-semibold rounded-full transition-all duration-300 bg-[#2E7A32] text-white shadow-md"
        >
          User Credentials
        </button>
      </div>
      {!loading && user && (
        <div className="flex flex-col items-center bg-sky-200 px-4">
          {currentSection === 'info' && <UserInfoForm user={user} />}
          {currentSection === 'creds' && (
            <UpdatePasswordForm
              user={user}
              setCurrentSection={setCurrentSection}
            />
          )}
        </div>
      )}
      {loading && (
        <div className="flex justify-center items-center w-full h-full">
          <p className="text-gray-700">Loading reservations...</p>
        </div>
      )}
      {error && !user && (
        <div className="flex justify-center items-center w-full h-full">
          <p className="text-red-600 font-bold">{error} - Please try again</p>
        </div>
      )}
    </div>
  );
}

function UserInfoForm({ user }) {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    address: user.address,
    city: '',
    zip: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    dispatch(
      updateCurrentUser({
        firstName: formData.firstName,
        lastName: formData.lastName,
        address: formData.address,
      }),
    );
  };

  return (
    <div className="w-full max-w-md bg-white my-8 p-8 rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-6 text-center">
        Update User Info
      </h2>
      <form onSubmit={handleUpdateUser}>
        <Input
          name="firstName"
          type="text"
          label="First Name"
          value={formData.firstName}
          onChange={handleInputChange}
        />
        <Input
          name="lastName"
          type="text"
          label="Last Name"
          value={formData.lastName}
          onChange={handleInputChange}
        />
        <Input
          name="address"
          type="text"
          label="Address"
          value={formData.address}
          onChange={handleInputChange}
        />
        <Input
          name="city"
          type="text"
          label="City"
          value={formData.city}
          onChange={handleInputChange}
        />
        <Input
          name="zip"
          type="text"
          label="Zip Code"
          value={formData.zip}
          onChange={handleInputChange}
        />
        <button
          type="submit"
          className="w-full bg-[#2E7A32] text-white py-2 px-4 mt-4 rounded hover:bg-[#236326] transition duration-200"
        >
          Submit
        </button>
      </form>
    </div>
  );
}

function UpdatePasswordForm({ setCurrentSection }) {
  const dispatch = useDispatch();
  const [isMatch, setIsMatch] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState('');

  const [formData, setFormData] = useState({
    oldPassword: '',
    password: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUpdatePass = async (e) => {
    e.preventDefault();
    dispatch(
      updateCurrentUser({
        oldPassword: formData.oldPassword,
        password: confirmPassword,
      }),
    ).then(() => {
      setCurrentSection('info');
    });
  };

  useEffect(() => {
    if (formData.password !== confirmPassword) {
      setIsMatch(false);
    } else {
      setIsMatch(true);
    }
  }, [confirmPassword, formData.password]);

  return (
    <div className="w-full max-w-md bg-white my-8 p-8 rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-6 text-center">
        Update Password
      </h2>
      <form onSubmit={handleUpdatePass}>
        <Input
          name="oldPassword"
          type="password"
          label="Current Password"
          value={formData.oldPassword}
          onChange={handleInputChange}
        />
        <Input
          name="password"
          type="password"
          label="New Password"
          value={formData.password}
          onChange={handleInputChange}
        />
        <Input
          name="confirmPassword"
          type="password"
          label="Confirm Password"
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
        />
        {!isMatch && (
          <p className="text-red-500 text-sm mt-2"> Password did not match</p>
        )}
        <button
          type="submit"
          className="w-full bg-[#2E7A32] text-white py-2 px-4 mt-4 rounded hover:bg-[#236326] transition duration-200"
        >
          Submit
        </button>
      </form>
    </div>
  );
}
