import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import equipmentReducer from './slices/equipmentsSlice';
import reservationReducer from './slices/reservationSlice';
import userReducer from './slices/userSlice';
import myselfReducer from './slices/myselfSlice'


export default combineReducers({
  auth: authReducer,
  equipments: equipmentReducer,
  reservations: reservationReducer,
  users: userReducer,
  myself: myselfReducer,
});

