import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectEquipment({ setFilters }) {
  const [equipmentType, setEquipmentType] = React.useState('');

  const handleChange = (event) => {
    const selectedType = event.target.value;
    setEquipmentType(selectedType);
    setFilters((prev) => ({ ...prev, equipmentType: selectedType }));
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 190, color: 'black' }} size="small">
      <InputLabel sx = {{ fontWeight:700 , color: 'lightblack'}} id="select-equipment-label">Equipment Type</InputLabel>
      <Select
        labelId="select-equipment-label"
        id="select-equipment"
        value={equipmentType} 
        label="Equipment Type"
        onChange={handleChange} 
        MenuProps={{
          PaperProps: {
            sx: {
              backgroundColor: 'black', // Set black background for the menu
              color: 'white', // Set text color to white for visibility
              '& .MuiMenuItem-root': { // Style individual menu items
                '&:hover': {
                  backgroundColor: '#333', // Optional: change background on hover
                },
              },
            },
          },
        }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value="compost spreader">compost spreader</MenuItem>
        <MenuItem value="tractor">tractor</MenuItem>
        <MenuItem value="boomerang">fun</MenuItem>
      </Select>
    </FormControl>
  );
}
