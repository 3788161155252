import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Navbar from '../components/Navbar';

import HomePageBG from '../assets/HomepageBG.jpg';
import ContentBox from '../components/ContentBox';
import PictureContentLeft from '../components/PictureContentLeft';
import SliProcessContent from '../assets/SLI_process.svg';
import ExternalLinkButton from '../components/ExternalLinkButton';
import { getCurrentUser } from '../redux/slices/myselfSlice';

const HomePage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  return (
    <div>
      <Navbar
        src={HomePageBG}
        pageName={'Sustainable Land Initiative'}
        pageText={
          'Providing assistance with best practices for water management, soil quality, erosion, and reducing climate impacts, while protecting your bottom line.'
        }
      />
      <ContentBox
        title="No-Cost Support for Better Land Stewardship"
        content={
          <div>
            Climate-smart agricultural practices can increase soil health and
            fertility, reduce water usage and recharge groundwater. This means
            less pumping and nutrient runoff issues. It can also mean increased
            crop yield and quality, better nutrient density, and improved
            economic returns. The Sustainable Land Initiative (
            <font class="font-bold">SLI</font>) is here to provide resources and
            assistance.
            <p>&nbsp;</p>
            This includes:
            <ul class="indent-3 list-inside">
              <li style={{ listStyleType: 'square' }}>
                Farm analysis and farm plan design.{' '}
              </li>
              <li style={{ listStyleType: 'square' }}>
                Consultation to identify strategies that address{' '}
                <strong>your</strong> goals for your land.{' '}
              </li>
              <li style={{ listStyleType: 'square' }}>
                Assistance obtaining funding, supplies, and equipment.{' '}
              </li>
              <li style={{ listStyleType: 'square' }}>
                Training and professional development.{' '}
              </li>
              <li style={{ listStyleType: 'square' }}>
                Assistance with farm electrification.{' '}
              </li>
              <li style={{ listStyleType: 'square' }}>
                Access to new market opportunities.{' '}
              </li>
            </ul>
            <div className="py-10">
              <ExternalLinkButton
                href="https://usltrcd-prod-mobile.f2-cloud.com/SelfService/submission/submit/Intake_form"
                target="_blank"
              >
                Get started now
              </ExternalLinkButton>
            </div>
          </div>
        }
        bgColor={'#CFE3F6'}
        titleColor={'#2E7A32'}
        textAlign={'text-left'}
      />
      <PictureContentLeft
        title="How It Works"
        titleColor="#2E7A32"
        content={
          <div>
            <ul>
              <li className="mb-3" style={{ listStyleType: 'square' }}>
                <strong>Submit Intake Form.</strong> Fill out a short form with
                information about your land. Our staff will reach out to
                schedule a site visit.{' '}
              </li>
              <li className="mb-3" style={{ listStyleType: 'square' }}>
                <strong>Site Assessment.</strong> Our staff will visit your farm
                to ask some questions, take some notes, and answer your
                questions. This usually takes 2-3 hours.{' '}
              </li>
              <li className="mb-3" style={{ listStyleType: 'square' }}>
                <strong>Plan Design.</strong> Our staff will develop a plan with
                recommended practices for the different "ecosites" on your farm
                (e.g., orchard, row crop field, pasture, creek).{' '}
              </li>
              <li className="mb-3" style={{ listStyleType: 'square' }}>
                <strong>Consult and Prioritize.</strong> We meet with you to
                review the plan and determine which practices interest you most.{' '}
              </li>
              <li className="mb-3" style={{ listStyleType: 'square' }}>
                <strong>Select Funding.</strong> SLI applies for grant funding
                on a rolling basis. We may already have funding suitable for
                your land, in which case you proceed immediately to
                implementation. Otherwise, your needs will be included in our
                next applications. Different funding agencies have different
                requirements; we'll help you find something that works for you.{' '}
              </li>
              <li className="mb-3" style={{ listStyleType: 'square' }}>
                <strong>Implementation.</strong> Our staff will have materials
                delivered to your location and assist with obtaining necessary
                equipment (see Farmshare).{' '}
              </li>
              <li className="mb-3" style={{ listStyleType: 'square' }}>
                <strong>Monitoring.</strong> Depending on the funding program,
                SLI may visit your farm to measure impacts. Collecting
                scientific data is required by some sponsors.{' '}
              </li>
              <li className="mb-3" style={{ listStyleType: 'square' }}>
                <strong>Market Connections.</strong> In preparation for harvest,
                our team will help connect you with buyers that value
                climate-smart food.
              </li>
            </ul>
          </div>
        }
        srcImg={SliProcessContent}
        btnContent={
          <ExternalLinkButton
            href="https://usltrcd-prod-mobile.f2-cloud.com/SelfService/submission/submit/Intake_form"
            target="_blank"
          >
            Contact us to learn more
          </ExternalLinkButton>
        }
        btnColor="#A8C356"
        display="inline-block"
      />
    </div>
  );
};

export default HomePage;
